.todo-input {
  margin: 15px 0 10px;
}

.todo-input button {
  margin-left: 10px;
}

.todo-input input {
  background-color: whitesmoke;
}

.todo-list {
  list-style: none;
  padding: 0;
}

.todo-list-item {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  list-style: none;
  background-color: #e9ecef;
  border: solid 2px;
  box-sizing: border-box;
}

.todo-list-item:hover {
  border: solid 4px;
}

.todo-list-item-text {
  vertical-align: middle;
}

